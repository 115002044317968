<template>
  <div id="app" class="app-background">
    <header>
      <!-- Icon in the top-right corner -->
      <img src="@/assets/logo-removebg.png" alt="Icon" class="header-icon" />

      <nav>
        <router-link to="/news">News</router-link>
        <router-link to="/calendar">Calendar</router-link>
        <router-link to="/songs">Songs</router-link>
      </nav>
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Full background image */
.app-background {
  background-image: url('@/assets/header-225x300.jpeg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  color: white;
  text-align: center;
}

/* Icon in top-right corner, quadratic, 10% of screen width */
.header-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 90%;
  height: 15vw; /* Make it square */
  object-fit: cover;
  border-radius: 8px; /* Optional: Adds a small border radius */
}

/* Centered navigation */
nav {
  margin-top: 20px;
}

nav a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}
</style>
